import React from "react";
import { Container, Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';

function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Les néo-banques – C’est quoi ?</title>
                <meta name="description" content="
             Qui sont dérière ces nouvelles néobanques qui font tant parler d'eux ? Carte de paiement, application, coûts cachés - on vous dit tout !" />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/academie/`} style={{color: 'black'}}>Banque Académie</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Les néo-banques – C’est quoi ?</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title id='mobileTitle'>Les néo-banques – C’est quoi ?</Title>
			<StaticImage
		  	src='../../images/les-neobanques-cest-quoi.jpeg'
			width={1200}
			alt='Les néobanques'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<Post>
		<p>Apparues dès 2009 aux Etats-Unis, les néo-banques, aussi appelées banques digitales ou 100 % mobile, ont poursuivi leur conquête mondiale au Royaume-Uni, en Allemagne et débarquent aujourd’hui en France. On peut citer Anytime, Compte Nickel, C-Zam, N26, Revolut et Orange Bank. Quels changements apportent ces banques nouvelle génération ? Comment fonctionnent-elles? Peut-on comparer les banques en ligne aux néo-banques ?
		</p>	
<h2>Principe de fonctionnement des banques digitales</h2>
<p>Les néo-banques ne sont pas à proprement parler des banques mais des établissements de paiement. Ainsi, elles mettent à disposition de leurs clients une carte bancaire adossée à un compte dont la gestion ne se fait que digitalement et plus particulièrement par smartphone via une application. Les services se limitent donc à l’essentiel d’un compte courant.
</p>
<h2>Une carte de paiement pour réaliser ses dépenses</h2>
<p>Les banques digitales proposent à leurs clients une carte de paiement à autorisation systématique. Ainsi, le particulier ne peut dépasser le solde disponible sur son compte et donc n’engendre pas de frais de découvert sauf pour Orange Bank et Soon qui permettent au particulier de bénéficier d’un découvert autorisé. Pour la plupart d’entre elles, la cotisation carte est payante à l’exception de Orange Bank qui ne la facture pas à condition de réaliser au moins trois opérations par mois avec celle-ci et de N26 dont la gratuité est conditionnée à neuf paiements par trimestre avec la carte.
</p>
<h2>Les entreprises derrière les banques 100 % mobile</h2>
<p>Ces banques 100 % mobile peuvent provenir soit de starts-up ayant établi des relations partenariales avec des acteurs traditionnels pour se constituer en tant qu’établissement de paiements, soit issues de grands groupes. On remarque ainsi que C-Zam est issu de Carrefour Banque et Soon à Axa Banque.
</p>
<h2>Le coût des banques numériques</h2>
<p>La tarification des néo-banques comprend généralement une cotisation carte et/ou des frais de tenue de compte. Chez Nickel le coût s’élève à 20€/an, 5€ pour l’acquisition de la carte et 1€/mois de frais de tenue de compte chez C-zam tandis que Anytime facture 5€ pour le service destiné à fournir la carte puis 22€/an. Chez Orange Bank, l’utilisation du compte et la carte sont gratuits à condition d’utiliser la carte, dans le cas contraire 5€/mois de frais de tenue de compte seront facturés. Soon pratique ce même type de facturation (frais non communiqués) ainsi que N26 qui facturent 2,90€/mois pour les plus de 26 ans en cas de non respect des conditions.
</p>
<h2>Les néo-banques ne sont pas des banques en ligne</h2>
<p>Les banques en ligne sont des banques à proprement parler et appartiennent toutes à des grandes filiales de banques traditionnelles. Les néo-banques sont des établissements de paiements fruit de start-up ou de partenariats avec des acteurs traditionnels.
</p><p>
Le support privilégié des banques en ligne est l’ordinateur tandis que les banques digitales favorisent le téléphone par le biais d’une application.
</p><p>
Les banques en ligne pratiquent la gratuité de la carte de paiement tandis que celle-ci est payante chez les banques 100% mobile (ou frais de tenue de compte ou facturation en cas d’utilisation insuffisante de la carte).
</p><p>
Les gammes de produits des banques en ligne sont plus étendues (épargne, bourse) alors que les néo-banques se limitent généralement au compte courant.
</p><p>
L’ouverture de compte d’une banque en ligne se fait par ordinateur avec des conditions de revenus et un certain nombre de justificatifs. Chez les banques digitales, l’ouverture du compte se fait rapidement sur mobile, sans engagement et ne nécessite que peu de justificatifs.
</p><p>Même si elles offrent plus de rapidité grâce à la dématérialisation, le mode de fonctionnement des banques en ligne se rapproche de celui des banques traditionnelles tandis que les néo-banques bénéficient de l’instantanéité.
</p>	</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
